import { useContext, useEffect } from 'react'
import { Box } from '@chakra-ui/react'
import OurPromiseModal from '../modals/OurPromise'
import SignUpModal from '../modals/SignUp'

import { useMediaQuery } from '@chakra-ui/react'

import { UserContext } from '../../store/user'

const TopBar = ({SelectProject, DesktopView, TabletView, elements, login}) => {
	const { state: userState } = useContext(UserContext)

	const [isTablet] = useMediaQuery('(max-width: 1140px)')
	return (
		<Box position="relative" zIndex="20">
			{elements && userState.token && <SelectProject />}
			<Box position="absolute" top="20px" right={"20px"}>
				{!isTablet ? <DesktopView /> : <TabletView/>}
			</Box>
			<SignUpModal login={login} />
			<OurPromiseModal />
		</Box>
	)
}

export default TopBar
