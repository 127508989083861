import { createContext, useReducer, useEffect, useState } from 'react'
import { useToast, useDisclosure } from '@chakra-ui/react'
import { loadStripe } from "@stripe/stripe-js"
import { toastOptions, useToastAsync } from '../utils/client/toast'
import { getPathName } from '../utils/client'
import axios from 'axios'
import { useRouter } from 'next/router'

const portalToastOptions = { title: 'Loading Billing' }
const checkoutToastOptions = { title: 'Loading Checkout' }

const isDev = !process.env.NEXT_PUBLIC_SENTRY_DSN
const Sentry = !isDev ? require('@sentry/nextjs') : null

const initialState = { token: null, user: null }
const UserContext = createContext(initialState)

const stripePromise = loadStripe(process.env.NEXT_PUBLIC_STRIPE_PUBLIC_KEY)

const UserProvider = ({ children }) => {
	const router = useRouter()
	const toast = useToast()
	const [isUserTokenLoaded, setIsUserTokenLoaded] = useState(false)

	const setShowPortalToast = useToastAsync(false, portalToastOptions)
	const setShowCheckoutToast = useToastAsync(false, checkoutToastOptions)

	const [state, dispatch] = useReducer((state, action) => {
		switch (action.type) {
			// user
			case 'SET_USER_TOKEN':
				localStorage.setItem('token', action.value)
				return { ...state, token: action.value }
			case 'SET_USER':
				Sentry?.setUser({ email: action.value.email })
				gtag('set', 'user_properties', { user_id: action.value.id })
				return { ...state, user: action.value }
			case 'SET_LOGOUT_USER':
				localStorage.removeItem('token')
				return { ...state, token: null, user: null }
		}
	}, initialState)

	const logout = () => {
		dispatch({ type: 'SET_LOGOUT_USER' })
	}

	const showPortal = async () => {
		if (state.user?.subscriptionStatus === 'active') {
			setShowPortalToast(true)
			const { data } = await axios.get(`/api/auth/portal`, { headers: { authorization: state.token } })
			window.location.href = data.url
			setShowPortalToast(false)
		} else {
			setShowCheckoutToast(true)
			const stripe = await stripePromise
			const { data } = await axios.get('/api/auth/checkout', { headers: { authorization: state.token } })
			await stripe.redirectToCheckout({ sessionId: data.sessionId })
			setShowCheckoutToast(false)
		}
	}

	const loginSuccess = async (res, save = false, kind) => {
		try {
			const { data } = await axios.post('/api/auth/continue', { code: res.code, kind: kind.type, ...(save && { data: kind.data }) })
			if (data.sessionId) {
				const stripe = await stripePromise
				setShowCheckoutToast(true)
				await stripe.redirectToCheckout({ sessionId: data.sessionId })
				setShowCheckoutToast(false)
			} else {
				dispatch({ type: 'SET_USER_TOKEN', value: data.token })
			}

		} catch (error) {
			toast({ title: "Error", variant: 'subtle', description: "Please try again later :(", status: "error", ...toastOptions })
			console.log(error)
		}
	}
	const loginError = async (e) => {
		if (e.error === 'idpiframe_initialization_failed') return

		toast({ title: "Error", variant: 'subtle', description: "Please try again later :(", status: "error", ...toastOptions })
	}

	const loadUser = async () => {
		const {data} = await axios.get(`/api/auth/user`, { headers: { 'Content-Type': 'application/json', authorization: state.token } })
		dispatch({type: "SET_USER", value: data.user})
	}

	const getSession = async (sessionId) => {
		const {data} = await axios.get(`/api/auth/session?session_id=${sessionId}`, { headers: { 'Content-Type': 'application/json' } })
		dispatch({ type: 'SET_USER_TOKEN', value: data.token })
		toast({ title: "You changed plan", variant: 'subtle', description: "You have subscribed to the \"Premium Plan\"!", status: "success", ...toastOptions })
		router.push(router.pathname, null, { shallow: true })
	}
	useEffect(() => {
		const sessionId = router.query.session_id
		if (sessionId && sessionId !== 'null') {
			getSession(sessionId)
		}
	}, [router.query.session_id])

	useEffect(() => {
		if(state.token) {
			loadUser()
		}
	}, [state.token])


	useEffect(() => {
		const token = localStorage.getItem('token')
		if (token !== '' && token !== 'null') {
			dispatch({type: "SET_USER_TOKEN", value: token})
		}
		setIsUserTokenLoaded(true)
	  }, [])

	const signupModalDisclosure = useDisclosure()
	const promiseModalDisclosure = useDisclosure()
	const selectMenuDisclosure = useDisclosure()
	const accountMenuDisclosure = useDisclosure()
	const mobileMenuDisclosure = useDisclosure()
	const disclosures = { signupModalDisclosure, promiseModalDisclosure, selectMenuDisclosure, accountMenuDisclosure, mobileMenuDisclosure }

	return <UserContext.Provider value={{ state, dispatch, logout, showPortal, loginSuccess, loginError, disclosures, isUserTokenLoaded }}>{children}</UserContext.Provider>
}

export { UserProvider, UserContext }