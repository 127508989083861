import { useContext } from 'react'

import { Button, Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, useDisclosure, useToast } from "@chakra-ui/react"

import { UserContext } from '../../store/user'

const clientId = process.env.NEXT_PUBLIC_GOOGLE_CLIENT_ID

const SignupModal = ({login}) => {
	const { state: userState, showPortal, disclosures } = useContext(UserContext)

	const status = userState.user?.subscriptionStatus

	return (

		<Modal isOpen={disclosures.signupModalDisclosure.isOpen} onClose={disclosures.signupModalDisclosure.onClose}>
			<ModalOverlay />
			<ModalContent>
				<ModalHeader>Join Pretty Funnels</ModalHeader>
				<ModalCloseButton />
				<ModalBody>
					Are you looking to save your work? <br />
					<br />
						We just <b>launched subscriptions</b> and we are having a discount 🎉 for our first 550 users!<br />
					<br />
						First <b>50</b> users: <br />
						→ €5/mo 🥇 <s>(€25/mo)</s><br />
					<br />
						The next <b>500</b> users:<br />
						→ €10/mo 🥈 <s>(€25/mo)</s>

					{/* <FormControl>
							<Input type="email" mt="5" placeholder="Email eg. seth@godin.com" onChange={(e) => setEmail(e.target.value)} required />
							<FormHelperText>We'll never share your email.</FormHelperText>
						</FormControl> */}
				</ModalBody>

				<ModalFooter>
					<Button variant="ghost" mr={3} onClick={disclosures.signupModalDisclosure.onClose}>Close</Button>
					{userState.token && status !== 'active' ?
						<Button onClick={() => { showPortal(); disclosures.signupModalDisclosure.onClose() }} marginRight="16px" colorScheme="purple">Subscribe ($5/mo)</Button> :
						<Button marginRight="16px" colorScheme="purple" onClick={() => { login(); disclosures.signupModalDisclosure.onClose()}}>Subscribe ($5/mo)</Button>}
				</ModalFooter>
			</ModalContent>
		</Modal>
	)
}
export default SignupModal