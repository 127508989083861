import { useEffect, useRef, useState, useMemo } from 'react'
import { useToast, Spinner, Alert, chakra, AlertTitle, AlertDescription } from '@chakra-ui/react'

const ToastAsync = ({ title = 'Loading...', description, id }) => {
	return (
		<Alert status="info" variant="left-accent" background="gray.100" colorScheme="purple" id={id?.toString()} borderRadius="md" boxShadow="lg" paddingEnd={8} textAlign="start" width="auto" alignItems="center">
			<Spinner color="var(--chakra-colors-purple-500)"/>
			<chakra.div flex="1" marginLeft="4">
				<AlertTitle>{title}</AlertTitle>
				{description && <AlertDescription display="block">{description}</AlertDescription>}
			</chakra.div>
		</Alert>
	)
}

const useToastAsync = (loading, options) => {
	const toastOptions = useMemo(() => options)
	const toast = useToast()
	const toastRef = useRef()
	const [isLoading, setShowSpinnerToast] = useState(loading ?? false)

	useEffect(() => {
		if (isLoading) {
			const duration = options?.duration ?? 100000
			if (duration) setTimeout(() => setShowSpinnerToast(false), duration)
			const optionsWithDuration = {...toastOptions, duration}
			toastRef.current = toast({ render: () => <ToastAsync {...optionsWithDuration} />, ...optionsWithDuration })
		} else if (toastRef.current) {
			toast.close(toastRef.current)
		}
	}, [isLoading, toast, toastOptions])

	return setShowSpinnerToast
}

export { useToastAsync }

const toastOptions = { duration: 5000, isClosable: true }
export {toastOptions}

