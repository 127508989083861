import { Button, Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton } from "@chakra-ui/react"
import { useContext } from 'react'
import { UserContext } from '../../store/user'

const OurPromiseModal = () => {
	const { disclosures } = useContext(UserContext)
	return (

		<Modal isOpen={disclosures.promiseModalDisclosure.isOpen} onClose={disclosures.promiseModalDisclosure.onClose}>
			<ModalOverlay />
			<ModalContent>
				<ModalHeader>Our Promise</ModalHeader>
				<ModalCloseButton />
				<ModalBody>
					Pretty Funnels <b>will always have</b> a free funnel builder to create 🛠 and share 📧 funnels.
				<br />
					<br />
					<span style={{ fontSize: '14px' }}>
						Our business is supported by the paid version with funnel saving 🚀.
				</span>
					<br />
					<br />
					<span style={{ fontSize: '18px' }}>As long as we operate the <b>funnel builder will be free!</b> ✌️</span>
				</ModalBody>
				<ModalFooter>
					{/* <Button colorScheme="blue" mr={3} onClick={onClose}>
					Close
					</Button> */}
					<Button variant="ghost" onClick={disclosures.promiseModalDisclosure.onClose}>Sounds Good</Button>
				</ModalFooter>
			</ModalContent>
		</Modal>
	)
}
export default OurPromiseModal